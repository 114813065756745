<template>
  <div class="contract">
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="form.name" placeholder="名称"></el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="form.mail" placeholder="邮箱"></el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="form.mobile" placeholder="电话"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="screen">筛选</el-button>
        </el-col>
        <el-col :span="2">
          <el-button @click="reset">显示全部</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="qmdate" align="center" label="日期">
      </el-table-column>
      <el-table-column prop="username" align="center" label="姓名">
      </el-table-column>
      <el-table-column prop="email" align="center" label="邮箱">
      </el-table-column>
      <el-table-column prop="mobile" align="center" label="电话">
      </el-table-column>
      <el-table-column prop="yhaddress" align="center" label="地址">
      </el-table-column>
      <el-table-column prop="signature" align="center" label="签名">
        <template slot-scope="scope">
          <img class="signature" :src="scope.row.signature" alt="" />
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSignList } from "@/api/contract.js";
export default {
  data() {
    return {
      tableData: [{}],
      form: {
        name: "",
        mail: "",
        mobile: "",
      },
      page: 0,
      limit: 10,
      total: 0, //总数
    };
  },
  methods: {
    // 重置请求
    reset() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = "";
      });
      (this.schoolname = ""), (this.cityname = ""), (this.page = 1);
      this.getList();
    },
    //筛选
    screen() {
      this.page=1;
      this.getList();
    },
    //切换每页条数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.reset();
    },
    //切换页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    getList(){
      let obj = this.form
      getSignList({
        username:obj.name,
        email:obj.mail,
        mobile:obj.mobile,
        count:this.limit,
        page:this.page
      }).then((res) => {
        console.log(res.data);
        this.tableData = res.data.data;
        this.total = res.data.total
      });
    }
  },
  mounted() {
    this.getList()
  },
};
</script>

<style scoped lang='scss'>
.contract {
  /deep/ .el-table {
    .signature {
      height: 60px;
      border-radius: 2px;
      border: 1px solid #f3f3f3;
    }
  }
  .fy {
    text-align: center;
    padding: 40px 0px;
  }
  .form {
    margin-bottom: 33px;
    /deep/ .el-button,
    .el-input,
    .el-autocomplete {
      width: 100%;
    }
    /deep/ .el-autocomplete-suggestion__wrap {
      li {
        line-height: normal;
        padding: 7px;
        .zw {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .yw {
          font-size: 12px;
          color: #b4b4b4;
        }

        .highlighted .zw {
          color: #ddd;
        }
      }
    }
  }
}
</style>